ion-toolbar.navBar {
    --background: var(--ion-color-bc-blue-always);
}

ion-split-pane {
    top: 62px;
}

/*form ion-item ion-label {*/
/*    color: #999999 !important;*/
/*}*/

ion-content div.pageContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*justify-content: center;*/
    /*background-color: #fafafa;*/
    height: 100%;
}

div.pageContentContainer .maxWidth {
    width: 100%;
    max-width: 980px;
    /*height: 100%;*/ /* this one is the problem ?! */
}

@media (prefers-color-scheme: dark) {
    ion-header ion-toolbar ion-title {
        color: #ffffff;
    }
}

ion-segment-button {
    padding: 6px 4px;
}

ion-col.selectLockingSystem ion-select {
    min-height: 36px;
}

.ios ion-col.selectLockingSystem {
    padding: 12px 12px 12px 0;
    /*height: 60px;*/
}

.md ion-col.selectLockingSystem {
    padding: 8px;
}

.ios ion-col.selectLockingSystem ion-select {
    border: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 0 20px;

}
.md ion-col.selectLockingSystem ion-select {
    height: 42px;
    border: 1px solid rgba(0, 0, 0, 0.13);
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0,0, 0, 0.12);
    padding: 0 16px;
}

.page-list.ion-page ion-content,
.page-item.ion-page ion-content {
    --background: #fafafa;
}
@media (prefers-color-scheme: dark) {
    .page-list.ion-page ion-content,
    .page-item.ion-page ion-content {
        --background: #000000;
    }
}

.page-list ion-list ion-item ion-button,
.page-item-keys ion-list ion-item ion-button,
.page-item-locks ion-list ion-item ion-button,
.page-item-lockingSystemPermissions ion-list ion-item ion-button,
.page-item-lockingSystemZones ion-list ion-item ion-button,
.page-item-people ion-list ion-item ion-button {
    height: 36px;
}
.page-item-error .pageContentContainer ion-button.typeIcon,
.page-item-view .pageContentContainer ion-button.typeIcon {
    min-width: 62px;
    height:    62px;
}

/*.page-list .pageContentContainer div.maxWidth,*/
.page-item .pageContentContainer div.maxWidth {
    height: 100%;
    background-color: #ffffff;
}
@media (prefers-color-scheme: dark) {
    /*.page-list .pageContentContainer div.maxWidth,*/
    .page-item .pageContentContainer div.maxWidth {
        background-color: #0A0A0A;
    }
}

/*ion-note.message {*/
/*    width: 100%;*/
/*    text-align: center;*/
/*}*/
div.message {
    line-height: 2;
    text-align: center;
}
ion-note ion-button {
    min-width: unset;
    height: unset;
}
.message.message-error {
    background-color: var(--ion-color-danger);
    color: white;
}
.message.message-notice {
    background-color: var(--ion-color-medium);
    color: var(--ion-color-light);
}
.message.message-info {
    background-color: var(--ion-color-primary);
    color: white;
}
.message.message-blank {
    background-color: transparent;
    color: var(--ion-color-dark);
}
.message.message-success {
    background-color: var(--ion-color-success);
    color: white;
}
.message.message-warning {
    background-color: var(--ion-color-warning);
    color: white;
}
.message .message-badge {
    border-radius: 4px;
    color: white;
    display: inline-block;
    margin: 0 3px;
    padding: 3px 6px;
}
.message .message-badge.changed {
    background-color: var(--ion-color-primary)
}
.message .message-badge.new {
    background-color: var(--ion-color-success)
}
.message .message-badge.removed {
    background-color: var(--ion-color-danger);
}
.message .message-badge.same {
    background-color: var(--ion-color-secondary)
}
.message .message-badge.total {
    background-color: transparent;
    color: white;
}
.message .message-badge.text {
    background-color: transparent;
    border: 1px solid #ccc;
    color: white;
}

.alert-select-width-90 .alert-wrapper {
    width: 90%;
    max-width: 500px;
}

ion-item.bc-accordion {
    --background: var(--ion-color-step-100, #e6e6e6)
}

ion-item.hidden-by-default,
ion-item-sliding.hidden-by-default {
    --background: #eaeaea;
    filter: grayscale(15%);
    opacity: 0.75;
}