.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
ion-spinner {
    width: 25%;
    height: 25%;
}