/*ion-card-header.ios {*/
/*    display: flex;*/
/*    flex-flow: column-reverse;*/
/*}*/
ion-card-title {
    font-size: 36px;
}
#main .dashboard ion-icon {
    /*font-size: 64px;*/
}

div.dashboard ion-card:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0 4px 16px 0;
}

@media (prefers-color-scheme: dark) {
    div.dashboard ion-card:hover {
        box-shadow: rgba(255, 255, 255, 0.48) 0 4px 16px 0;
    }
}

.ion-page.dashboard ion-card ion-button {
    height: 36px;
}