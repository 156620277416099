.header-ios.list-page-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-left: 8px;
}
.list-page-header ion-toolbar {
    width: 100%;
    max-width: 980px;
    margin: 0 auto;
}
.header-ios ion-toolbar:last-of-type {
    --border-width: 0 0 0;
}

@media (min-width: 992px) {
    .header-ios.list-page-header {
        padding: 8px 12px;
    }
    .header-ios.list-page-header ion-toolbar ion-title {
        font-size: 24px;
        font-weight: 400;
    }
}

ion-header.list-page-header.ios {
    background-color: #f7f7f7;
}
ion-header.list-page-header.md {
    background-color: #ffffff;
}

@media (prefers-color-scheme: dark) {
    ion-header.list-page-header.ios {
        background-color: #0d0d0d;
    }
    ion-header.list-page-header.md {
        background-color: #1f1f1f;
    }
}
