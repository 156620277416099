ion-button#select-locking-system {
    margin: 0;
    --padding-start: 6px;
    --padding-end: 6px;
    --padding-top: 4px;
    --padding-bottom: 4px;
    height: 36px;
}
ion-button#select-locking-system.md {
    height: 42px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12)
}